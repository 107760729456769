export default {
  computed: {

    zones() {
      return this.$store.state.mainAppStore.zones
    },

  },
  created() {
    // eslint-disable-next-line no-unused-expressions
    this.showOverlay = true
    this.$store.dispatch('mainAppStore/fetchZonesListItems').then(response => {
      this.showOverlay = false
    })
      .catch(err => {
        console.error(err.response.headers)
      })
  },
}
